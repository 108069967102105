import React from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const CarouselItemTemplate = ({ image, heading }) => {
  const textBoxStyle = {
    boxShadow:
      'rgb(50, 115, 220) 0.5rem 0px 0px, rgb(50, 115, 220) -0.5rem 0px 0px',
    backgroundColor: 'rgb(50, 115, 220)',
    color: 'white',
    lineHeight: '1',
    padding: '0.25em',
    opacity: '0.85',
  }
  return (
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
      }}
    >
      <div
        style={{
          marginTop: '350px',
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={textBoxStyle}
        >
          {heading}
        </h1>
      </div>
    </div>
  )
}

CarouselItemTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
}

const IndexPageCarousel = ({ gridItems }) => (
  <Carousel style={{ maxWidth: '850px', margin: 'auto' }}>
    {gridItems.map((item) => (
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={item.image.childImageSharp.fluid.src}
        />
        <Carousel.Caption>
          <h2
            className="has-text-weight-bold is-size-5-mobile is-size-6-tablet is-size-4-widescreen d-none d-md-block"
            style={{
              boxShadow:
                'rgb(50, 115, 220) 0.5rem 0px 0px, rgb(50, 115, 220) -0.5rem 0px 0px',
              backgroundColor: 'rgb(50, 115, 220)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
              opacity: '0.85',
              top: '0',
            }}
          >
            {item.heading}
          </h2>
        </Carousel.Caption>

        {/* <CarouselItemTemplate image={item.image} heading={item.heading} /> */}
      </Carousel.Item>
    ))}
  </Carousel>
)

export default IndexPageCarousel
